import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import loadable from "@loadable/component";
import Loading from "./components/Loading";
import {AppContextProvider} from "./contexts/app";

const loadableOpts = { fallback: <Loading /> };

const HomePageComponent = loadable(
  () => import("./pages/HomePage"),
  loadableOpts
);
const MerchantAppPrivacyPolicy = loadable(
  () => import("./pages/MerchantAppPrivacyPolicy"),
  loadableOpts
);

function App() {
  return (
    <AppContextProvider>
      <Router>
        <Switch>
          <Route exact path="/" children={<HomePageComponent />} />
          <Route
            path="/merchant/privacy-policy"
            children={<MerchantAppPrivacyPolicy />}
          />
        </Switch>
      </Router>
    </AppContextProvider>
  );
}

export default App;
