import React from "react";

interface AppContextState {
  companyName: string;
}

export const AppContext = React.createContext<any>({});

export const AppContextProvider = ({ children }: { children: React.ReactChild }) => {
  const [state, setState] = React.useState<AppContextState>({
    companyName: "Fast Food Technology",
  });

  return (
    <AppContext.Provider
      value={{
        state,
        setState: (data: AppContextState) => setState(data),
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
